exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-build-blog-mdx": () => import("./../../../src/pages/blog/build-blog.mdx" /* webpackChunkName: "component---src-pages-blog-build-blog-mdx" */),
  "component---src-pages-blog-build-graphql-api-mdx": () => import("./../../../src/pages/blog/build-graphql-api.mdx" /* webpackChunkName: "component---src-pages-blog-build-graphql-api-mdx" */),
  "component---src-pages-blog-job-seeking-mdx": () => import("./../../../src/pages/blog/job-seeking.mdx" /* webpackChunkName: "component---src-pages-blog-job-seeking-mdx" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

